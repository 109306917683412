<template>
  <div class="container-paises" :class="{ girar: paises, 'position-absolute': positionAbsolute}">
    <div @click="openCloseCountryList" class="item-pais pais-selecionado">
      <img :src="require(`@/assets/locales/flag-${pais_code}.png`)" />
      {{ paises ? $t("locales.close") : $t("locales.trocar") }}
    </div>
    <ul class="paises" data-anima="top" v-show="paises" :style="!positionAbsolute ? 'position: absolute;background-color: white;z-index: 99;' : ''">
      <div v-for="list in filteredItems" :key="list.sigla">
        <li class="item-pais" @click="changeCountry(list.sigla, list.pais)">
          <img :src="require(`@/assets/locales/flag-${list.sigla}.png`)" />
          {{ list.sub }} | {{ list.pais }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { EventBus } from "@/main.js"

export default {
  name: "Locales",
  props: {
    showCountryListTab: {
      required: false,
      type: Boolean,
      default: false
    },
    positionAbsolute: {
     required: false,
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      pais: "",
      paises: false,
      pais_nome: "US | English",
      pais_code: "US",
      search:'',
      lists:[
        {sigla:'BR', sub:'Brazil', pais:'Brasil'},
        {sigla:'ES', sub:'España', pais:'Spain'},
        {sigla:'US', sub:'United States', pais:'United States'},
      ]
    }
  },
  computed: {
    filteredItems() {
      return !this.search.length 
        ? this.lists 
        : this.lists.filter(item => item.pais.toLowerCase().includes(this.search.toLowerCase().trim()) || item.sub.toLowerCase().includes(this.search.toLowerCase().trim()) || item.sigla.toLowerCase().includes(this.search.toLowerCase().trim()))
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  watch: {
    showCountryListTab() {
      if(this.showCountryListTab === true) this.paises = false
    }
  },
  methods: {
    openCloseCountryList() {
      this.paises = !this.paises
      if(this.paises) this.$emit('show-country-list')
    },
    changeCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code
        this.pais_nome = `${code} | ${lang}`
      }
      let data = { locale: code }
      EventBus.$emit("atualizaPais", data)
      this.$emit("update-country", data)
      this.selectCountry(code)
      this.paises = !this.paises
      if(this.paises) this.$emit('show-country-list')
    },
    selectCountry(locale) {
      this.search = ''
      if (locale === 'BR' || locale === 'pt' || !locale) {
        this.$i18n.locale = "pt";
        this.pais_nome = "BR | Português";
        this.pais_code = "BR";
      } else if (locale == "AR" ||
        locale == "CO" ||
        locale == "CL" ||
        locale == "EC" ||
        locale == "GT" ||
        locale == "MX" ||
        locale == "PE" ||
        locale == "UY" ||
        locale == "ES") {
        this.$i18n.locale = "es"
        this.pais_nome = "ES | España";
        this.pais_code = "ES";
      } else {
        this.$i18n.locale = "en"
        this.pais_nome = "US | English"
        this.pais_code = "US"
      }
    }
  },
  created() {
    this.isHeaven ? this.selectCountry('US') : this.selectCountry('BR')
    EventBus.$on("changeLocale", (data) => {
      if(data.locale == "BR" || data.locale == "pt") {
        this.selectCountry("BR");
        this.pais_nome = "BR | Português";
        this.pais_code = "BR";
      } else if(
          data.locale == "AR" ||
          data.locale == "CO" ||
          data.locale == "CL" ||
          data.locale == "EC" ||
          data.locale == "GT" ||
          data.locale == "MX" ||
          data.locale == "PE" ||
          data.locale == "UY" ||
          data.locale == "ES"
        ) {
        this.selectCountry(data.locale);
        this.pais_nome = "ES | España";
        this.pais_code = "ES";
      } else {
        this.selectCountry("US");
        this.pais_nome = "US | English";
        this.pais_code = "US";
      }
    })
  }
}
</script>

<style scoped>
/* // paises */
.container-paises {
  z-index: 9999;
  width: 180px;
  background-color: white;
}

.position-absolute {
  position: absolute;
  right: 0;
}

.input-busca {
  width: max-content;
  height: 40px !important;
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
  transition: 0.5s;
  margin-bottom: 15px;
}
.input-busca::placeholder {
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 32px;
  top: 27px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
    top: 43px;
  }
  .pais-selecionado {
    margin-left: 30px !important;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.paises {
  background: var(--bg-checkout);
  width: max-content;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.pais-selecionado {
  margin-left: 15px;
}
.pais-selecionado::after {
  content: "";
  background: url("../assets/img/icons/arrow-fill.svg") no-repeat center center;
  width: 22px;
  height: 22px;
  position: absolute;
  right: -10px;
}
.girar .pais-selecionado::after {
  transform: rotate(180deg);
  transition: all .3s;
}
.item-pais {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
  padding: 7px 10px;
  padding-right: 0;
  transition: 0.3s;
  color: var(--text-color);
  position: relative;
  margin-bottom: 5px;
}
.item-pais::before {
  content: "";
  width: 5px;
  height: 5px;
  background: transparent;
  position: absolute;
  border-radius: 50%;
}
.item-pais + .item-pais {
  margin-top: 10px;
}
.item-pais:hover {
  color: var(--main-color);
}
.item-pais:hover::before {
  background: var(--main-color);
}
.item-pais img {
  width: 20px;
  height: 20px;
}
</style>
