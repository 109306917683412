<template>
  <div>
    <div class="menu-flex">
      <div class="d-flex align-items-center">
        <a :href="`https://${isHeaven ? 'heaven.me' : 'greenn.com.br/'}`">
          <img v-if="!isHeaven" height="38" src="/img/logo.6880cf15.png" />
          <img v-else height="44" src="@/assets/img/heaven/logo.svg" />
        </a>
        <div class="mr-3">
          <Locales :positionAbsolute="false"/>
        </div>
      </div>
      <BaseButton
        v-if="$route.path === '/'"
        variant="secundary"
        :class="{'heaven': isHeaven}"
        @click="$router.push('/acompanhar')"
      >
        {{ $t('claim.text_004') }}
      </BaseButton>
      <BaseButton
        v-if="$route.path === '/acompanhar'"
        variant="secundary"
        :class="{'heaven': isHeaven}"
        @click="$router.push('/')"
      >
        {{ $t('claim.text_005') }}
      </BaseButton>
    </div>

    <div class="pt-5 pb-3 expande-horizontal centraliza-horizontal expande-vertical-90">
      <FormNewClaim v-if="$route.path == '/'" />
      <b-row
        v-if="$route.path == '/acompanhar'"
        class="expande-horizontal centraliza-horizontal"
        style="flex-wrap: wrap"
      >
        <b-col cols="12" md="9">
          <FormViewClaim />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Locales from '@/components/Locales'
import FormNewClaim from '@/components/claimForm/newClaim'
import FormViewClaim from '@/components/claimForm/viewClaim'

export default {
  components: { FormNewClaim, FormViewClaim, Locales },
  computed: {
    isHeaven() {
      return this.$store.getters['getIsHeaven']
    }
  }
}
</script>

<style>
.expande-horizontal {
  display: flex;
  width: 100%;
}

@media (max-width: 1143px) and (min-width: 700px) {
  .block.expande-horizontal,  .block.expande-horizontal div{
    display: block;
    max-width: inherit;
  }
}

@media (max-width: 699px) {
  .block.expande-horizontal, .block.expande-horizontal div {
    display: block;
    max-width: inherit;
  }
}

.wrap {
  flex-wrap: wrap;
}
.expande-vertical {
  min-height: 100vh;
}
.expande-vertical-90 {
  min-height: 90vh;
}
.centraliza-vertical {
  align-items: center;
}
.espacamento {
  width: 100%;
}
.centraliza-horizontal {
  justify-content: center;
}
.toolbar {
  max-height: 10vh;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 12px 6px 12px 2px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.background-reclamacao {
  background: #fff;
}
.container-rel {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  padding: 40px;
}

.menu-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  width: 100%;
  border-bottom: 1px solid #ededf0;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.04);
  gap: 20px;
}
</style>