<template>
  <b-col class="pa-4">
    <div class="expande-horizontal column centraliza-vertical wrap">
      <!-- Step 01 - It's a shame that you're facing challenges-->
      <div
        v-if="step === 1"
        class="container-rel"
        style="
          max-width: 701px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          min-height: 30vh;
          width: -webkit-fill-available;
        "
      >
        <b-col class="mb-4" cols="12" md="12" data-anima="top">
          <h4 class="mb-3 green--text title-green" :class="{'heaven': isHeaven}">
           {{ $t('new_claim.text_061') }}
          </h4>
          <span class="grey--text subtitle" style="margin-top: 22px; padding-bottom: 12px">
            {{ $t('new_claim.text_062') }}
          </span>
        </b-col>
        <!-- Invoice Number -->
        <b-col class="mt-2" cols="12" md="12" data-anima="top">
          <a href="" id="example" @click.prevent="example">
            <img src="@/assets/img/icons/help.svg" />
          </a>
          <b-tooltip target="example" :title="$t('new_claim.text_063')" />
          <b-form-group :label="$t('new_claim.text_064')" label-for="id_compra">
            <b-form-input
              id="id_compra"
              name="id_compra"
              :placeholder="$t('new_claim.text_065')"
              v-model="get_claim.sale_id"
              v-validate="'required'"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="submit ? !errors.has('id_compra') : null"
            >
              {{ $t('new_claim.text_066') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- CPF/CNPJ -->
        <b-col v-if="!isHeaven" class="mt-2" cols="12" data-anima="top">
          <b-form-group
            :label="$t('new_claim.text_067') + ' ' + $t('app.optional')"
            label-for="cpf_cliente"
          >
            <b-form-input
              id="cpf_cliente"
              name="cpf_cliente"
              :placeholder="$t('new_claim.text_068')"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              v-model="get_claim.document"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- Document Number -->
        <b-col v-if="isHeaven" class="mt-2" cols="12" data-anima="top">
          <b-form-group
            :label="$t('new_claim.text_117') + ' ' + $t('app.optional')"
            label-for="cpf_cliente"
          >
            <b-form-input
              id="cpf_cliente"
              name="cpf_cliente"
              :placeholder="$t('new_claim.text_118')"
              v-model="get_claim.document"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- Private information / Buttons -->
        <b-col cols="12" class="mt-4 px-3">
          <div class="expande-horizontal centraliza-vertical">
            <div style="width: 100%">
              <b-tooltip
                style="width: 100px"
                target="span-privacidade"
                :title="$t('new_claim.text_070')"
                placement="top"
              />
              <div class="expande-horizontal centraliza-vertical">
                <img
                  class="mr-2"
                  style="height: 15px"
                  src="@/assets/img/icons/eye-off-blue.svg"
                />
                <span class="grey--text subtitle">{{ $t('new_claim.text_071') }}</span>
              </div>
            </div>
            <BaseButton
              v-if="!loading"
              :disabled="!get_claim.sale_id && !original_claim.sale_id"
              variant="primary"
              :class="{'heaven': isHeaven}"
              @click="checkWarranty"
            >
              {{ $t('new_claim.text_072') }}
            </BaseButton>
            <BaseButton
              v-else
              variant="primary"
              :class="{'heaven': isHeaven}"
              style="min-width: 100px"
              :disabled="loading"
            >
              <b-spinner small></b-spinner>
            </BaseButton>
          </div>
        </b-col>
      </div>
      <!-- Step 02 - Claim Access Code-->
      <div
        v-if="step === 2"
        class="container-rel"
        style="
          max-width: 701px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          min-height: 30vh;
          width: -webkit-fill-available;
        "
      >
        <b-col class="mb-4" cols="12" md="12" data-anima="top">
          <h4 class="mb-3 green--text title-green" :class="{'heaven': isHeaven}">
           {{ $t('verification_email.text_119') }}
          </h4>
          <span class="grey--text subtitle" style="margin-top: 22px; padding-bottom: 12px">
            {{ $t('verification_email.text_120') }}
          </span>
        </b-col>
        <!-- Verification Code -->
        <b-col class="mt-2" cols="12" md="12" data-anima="top">
          <b-form-group :label="$t('verification_email.text_121')" label-for="id_compra">
            <b-form-input
              id="id_compra"
              name="id_compra"
              :placeholder="$t('verification_email.text_122')"
              v-model="get_claim.access_code"
              v-validate="'required'"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="submit ? !errors.has('id_compra') : null"
            >
              {{ $t('verification_email.text_123') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Reenviar E-mail / Buttons -->
        <b-col cols="12" class="mt-4 px-3">
          <div class="expande-horizontal centraliza-vertical">
            <div style="width: 100%">
              <div class="expande-horizontal centraliza-vertical">
                <span class="grey--text subtitle cursor-pointer" @click="backOne">{{ $t('verification_email.text_124') }}</span>
              </div>
            </div>
            <div class="d-flex">
              <BaseButton
                variant="link"
                :class="{'heaven': isHeaven}"
                class="mr-3"
                style="width: max-content;"
                @click="sendNewVerificationCode"
              >
                {{ $t('verification_email.text_125') }}
              </BaseButton>
              <BaseButton
                v-if="!loading"
                :disabled="!get_claim.access_code"
                variant="primary"
                :class="{'heaven': isHeaven}"
                @click="verificarClaimAccessCode"
              >
                {{ $t('new_claim.text_072') }}
              </BaseButton>
              <BaseButton
                v-else
                variant="primary"
                :class="{'heaven': isHeaven}"
                style="min-width: 100px"
                :disabled="loading"
              >
                <b-spinner small></b-spinner>
              </BaseButton>
            </div>
          </div>
        </b-col>
      </div>
      <!-- Step 03 - We need some information -->
      <div
        v-if="step === 3"
        class="container-rel"
        style="
          max-width: 701px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          min-height: 30vh;
          width: -webkit-fill-available;
        "
      >
        <b-col cols="12" md="12" data-anima="top">
          <h4 class="green--text title-green" :class="{'heaven': isHeaven}">
            {{ $t('new_claim.text_073') }}
          </h4>
        </b-col>
        <b-col class="mt-4" cols="12" md="12" data-anima="top">
          <div class="block expande-horizontal">
            <div style="display: flex; flex-direction: column">
              <span style="width: 300px" class="font-weight-bold">
                {{ $t('new_claim.text_074') }}
              </span>
              <span class="grey--text pt-2">{{ get_claim.id_sale }}</span>
            </div>
            <div class="spacer"></div>
            <div style="display: flex; flex-direction: column">
              <span style="width: 200px" class="font-weight-bold mb-2">
                {{ $t('new_claim.text_075') }}
              </span>
              <span class="grey--text"> {{ get_claim.seller_email }}</span>
              <span class="grey--text pt-2"> {{ get_claim.seller_phone }}</span>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="12" data-anima="top">
          <div class="expande-horizontal">
            <div style="display: flex; flex-direction: column">
              <span style="width: 300px" class="font-weight-bold">
                {{ $t('new_claim.text_076') }}
              </span>
              <span class="grey--text pt-3">{{ get_claim.document }}</span>
            </div>
            <div class="spacer"></div>
          </div>
        </b-col>
        <b-col class="mt-4 mb-3" cols="12" md="12" data-anima="top">
          <button
            class="button-text-blue cursor"
            variant="text-blue"
            @click="step = 1"
          >
            {{ $t('new_claim.text_077') }}
          </button>
        </b-col>
        <b-col
          v-if="get_claim.warranty == 'Produto fora de garantia'"
          class="mt-3"
          cols="12"
          md="12"
          data-anima="top"
        >
          <div class="expande-horizontal box-info">
            <img
              class="mr-3"
              style="position: relative; top: -20px"
              src="@/assets/img/icons/calendario-grey.svg"
            />
            {{ $t('new_claim.text_078') }}
          </div>
        </b-col>

        <b-col
          v-if="!newClaimForm"
          class="mt-3 px-0 mx-0"
          cols="12"
          md="12"
          data-anima="top"
        >
          <div class="block expande-horizontal">
            <b-col class="mt-3" cols="6" md="6" data-anima="top">
              <BaseButton
                style="width: 100%"
                variant="secundary"
                :class="{'heaven': isHeaven}"
                @click="newClaimForm = true"
              >
                {{ $t('new_claim.text_079') }}
              </BaseButton>
            </b-col>
            <b-col class="mt-3" cols="6" md="6" data-anima="top">
              <BaseButton
                style="width: 100%"
                variant="primary"
                :class="{'heaven': isHeaven}"
                @click="step = 4"
              >
                {{ $t('new_claim.text_080') }}
              </BaseButton>
            </b-col>
          </div>
        </b-col>
      </div>
      <!-- Step 03 - Please explain what is happening -->
      <div
        v-if="step === 3 && newClaimForm"
        class="container-rel mt-5"
        style="
          max-width: 701px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          min-height: 30vh;
          width: -webkit-fill-available;
        "
      >
        <b-col class="mb-4" cols="12" md="12" data-anima="top">
          <h4 class="mb-3 green--text title-green" :class="{'heaven': isHeaven}">
            {{ $t('new_claim.text_083') }}
          </h4>
          <span
            class="grey--text"
            style="margin-top: 22px; padding-bottom: 12px"
          >
            {{ $t('new_claim.text_084') }}
          </span>
        </b-col>
        <b-col cols="12" md="12" data-anima="top">
          <b-form-group
            :label="$t('new_claim.text_085')"
            label-for="titulo_reclamacao"
          >
            <b-form-input
              id="titulo_reclamacao"
              name="titulo_reclamacao"
              :placeholder="$t('new_claim.text_086')"
              v-model="get_claim.subjective"
              maxlength="40"
              v-validate="'required'"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="submit ? !errors.has('titulo_reclamacao') : null"
            >
              {{ $t('new_claim.text_087') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-2" cols="12" md="12" data-anima="top">
          <b-form-group
            :label="$t('new_claim.text_088')"
            label-for="encaixe_problema"
          >
            <b-form-select
              v-model="get_claim.category"
              :label="$t('new_claim.text_089')"
              id="encaixe_problema"
              name="encaixe_problema"
              :placeholder="$t('new_claim.text_090')"
              v-validate="'required'"
              :options="[
                { value: 'Não recebi o produto', text: $t('new_claim.text_091') },
                { value: 'Não gostei do produto', text: $t('new_claim.text_092'), },
                { value: 'Não está em conformidade com o que foi vendido', text: $t('new_claim.text_093') },
                { value: 'Não me deram um bom atendimento', text: $t('new_claim.text_094'), },
                { value: 'Outros', text: $t('new_claim.text_095') },
              ]"
              :class="{'heaven': isHeaven}"
            ></b-form-select>

            <b-form-invalid-feedback
              :state="submit ? !errors.has('encaixe_problema') : null"
            >
              {{ $t('new_claim.text_096') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-2" cols="12" md="12" data-anima="top">
          <b-form-group
            :label="$t('new_claim.text_097')"
            label-for="objetivo_reclamacao"
          >
            <b-form-select
              :placeholder="$t('new_claim.text_098')"
              id="objetivo_reclamacao"
              v-model="get_claim.objective"
              name="objetivo_reclamacao"
              v-validate="'required'"
              :options="optionsObjetive"
              :class="{'heaven': isHeaven}"
            ></b-form-select>

            <b-form-invalid-feedback
              :state="submit ? !errors.has('objetivo_reclamacao') : null"
            >
              {{ $t('new_claim.text_099') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12" data-anima="top">
          <b-form-group
            :label="$t('new_claim.text_100')"
            label-for="conte_sua_historia"
          >
            <b-form-textarea
              id="conte_sua_historia"
              name="conte_sua_historia"
              :placeholder="$t('new_claim.text_101')"
              rows="9"
              style="min-height: 150px"
              v-model="get_claim.description"
              v-validate="'required'"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-textarea>
            <b-form-invalid-feedback
              :state="submit ? !errors.has('conte_sua_historia') : null"
            >
              {{ $t('new_claim.text_102') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12" class="mt-4 px-3">
          <div class="expande-horizontal centraliza-vertical">
            <div style="width: 400px">
              <b-tooltip
                style="width: 100px"
                target="span-privacidade"
                :title="$t('new_claim.text_103')"
                placement="top"
              />
              <div class="expande-horizontal centraliza-vertical">
                <img
                  class="mr-2"
                  style="height: 15px"
                  src="@/assets/img/icons/eye-off-blue.svg"
                />
                <span class="grey--text" id="span-privacidade">
                  {{ $t('new_claim.text_104') }}
                </span>
              </div>
            </div>
            <BaseButton
              style="width: 300px"
              :disabled="canSubmit || loading"
              variant="primary"
              :class="{'heaven': isHeaven}"
              @click="create_claim_public"
            >
              {{ $t('new_claim.text_105') }}
            </BaseButton>
          </div>
        </b-col>
      </div>
      <!-- Step 04 - Great, we hope everything goes well -->
      <div
        v-if="step === 4"
        class="container-rel shaddows"
        style="
          max-width: 701px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          min-height: 20vh;
          width: -webkit-fill-available;
        "
      >
        <b-col cols="12" md="12" data-anima="top">
          <div class="expande-horizontal">
            <div class="avatar-">
              <img src="@/assets/img/icons/salvar-white.svg" />
            </div>
            <div style="display: flex; flex-direction: column">
              <h4 class="green--text">
                {{ $t('new_claim.text_081') }} {{ isHeaven ? 'Heaven' : 'Greenn' }}
              </h4>
              <span class="font-weight-bold mt-4 mb-2">{{ $t('new_claim.text_082') }}</span>
              <span class="grey--text"> {{ get_claim.seller_email }}</span>
              <span class="grey--text pt-2">{{ get_claim.seller_phone }}</span>
            </div>
          </div>
        </b-col>
      </div>
      <!-- Step 05 - Complaint completed successfully -->
      <div
        v-if="step === 5"
        class="container-rel shaddows"
        style="
          max-width: 701px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          min-height: 20vh;
          width: -webkit-fill-available;
        "
      >
        <b-col cols="12" md="12" data-anima="top">
          <div class="expande-horizontal">
            <div class="avatar-">
              <img src="@/assets/img/icons/salvar-white.svg" />
            </div>
            <div style="display: flex; flex-direction: column">
              <h4 class="green--text title-green">
                {{ $t('new_claim.text_106') }}
              </h4>
              <span class="pt-3 mb-3" style="font-weight: 500">
                {{ $t('new_claim.text_107') }} #{{ get_claim.id }}
              </span>

              <div v-if="warranty_aux !== 'Produto fora de garantia'">
                <span class="grey--text pt-3 text-finish">
                  {{ $t('new_claim.text_108') }}
                </span>
                <span class="grey--text pt-3 text-finish">
                  {{ $t('new_claim.text_109') }}
                </span>
              </div>
              <div v-if="warranty_aux == 'Produto fora de garantia'">
                <span class="grey--text pt-3 text-finish">
                  {{ $t('new_claim.text_110') }}
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </div>
    <ModalExample />
  </b-col>
</template>

<script>
import ClaimPublic from '@/services/resources/ClaimPublic'

import ModalExample from './ModalExample.vue'
const service = ClaimPublic.build()

export default {
  name: 'NewClaim',
  components: { ModalExample },
  data() {
    return {
      warranty_aux: "",
      step: 1,
      newClaimForm: false,
      submit: false,
      get_claim: {
        subjective: "",
      },
      optionsObjetive: [
        { value: "Quero a resolução do problema", text: this.$t('new_claim.text_111') },
      ],
      get_answers: [],
      loading: false,
      original_claim: {}
    }
  },
  computed: {
    canSubmit() {
      if (
        this.get_claim.objective &&
        this.get_claim.subjective &&
        this.get_claim.category &&
        this.get_claim.description
      ) {
        return false
      }
      return true
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    example() {
      this.$bvModal.show("modal-example");
    },
    check_claim_warranty() {
      this.loading = true
      return service
        .create({
          ...this.get_claim,
          language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR'
        }, null, "/verify")
        .then(res => {
          this.original_claim = Object.assign({}, this.get_claim)
          this.get_claim = res;
          if(res.warranty === "") {
            this.optionsObjetive.push({
              value: "Quero o cancelamento e meu dinheiro de volta",
              text: this.$t('new_claim.text_112')
            })
          }
          this.get_claim.sale_id = res.id_sale;
          this.original_claim.document ? this.step = 3 : this.step = 2
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('new_claim.text_113'), {
            variant: "danger",
            title: this.$t('new_claim.text_114'),
            autoHideDelay: 5000
          })
        })
        .finally(() => {
          this.loading = false
          this.submit = false
        })
    },
    create_claim_public() {
      this.loading = true
      this.original_claim = Object.assign({}, this.get_claim)
      service
        .create({
          language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR',
          ...this.get_claim,
          client_id: this.get_claim.cliend_id,
          sale_id: this.get_claim.id_sale
        })
        .then((res) => {
          this.warranty_aux = this.get_claim.warranty.toString();
          this.get_claim = res.data
          this.get_claim.seller_email = this.original_claim.seller_email
          this.get_claim.seller_phone = this.original_claim.seller_phone
          this.step = 4
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    async checkWarranty() {
      this.submit = true
      this.check_claim_warranty()
    },
    async sendNewVerificationCode() {
      this.get_claim = Object.assign({}, this.original_claim)
      this.checkWarranty()
    },
    async verificarClaimAccessCode() {
      await service.create({
        access_code: this.get_claim.access_code,
        sale_id: this.original_claim.sale_id,
        language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR'
      },
      null, "/verify-access-code")
        .then(res => {
          if(res) {
            this.get_claim = res;
            if(res.warranty === "") {
              this.optionsObjetive.push({
                value: "Quero o cancelamento e meu dinheiro de volta",
                text: this.$t('new_claim.text_112')
              })
            }
            this.get_claim.sale_id = res.id_sale;
            this.step = 3
          }
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('new_claim.text_113'), {
            variant: "danger",
            title: this.$t('verification_email.text_126'),
            autoHideDelay: 5000
          })
        })
    },
    backOne() {
      this.get_claim = Object.assign({}, this.original_claim)
      this.step = 1
    }
  }
}
</script>

<style scoped lang="scss">
.avatar- {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  margin-right: 30px;
  display: flex;
  background: #37cf79;
  align-items: center;
  justify-content: center;
}
.green--text {
  color: #37cf79;
}
.grey--text {
  color: #81858e;
}
.spacer {
  width: 100%;
}
.button-text-blue {
  background: transparent;
  color: #2133d2;
  border: none;
  font-size: 10pt;
  text-align: left;
  font-weight: 700;
}
.box-info {
  border-radius: 6px;
  background: #f5f5f5;
  font-size: 11pt;
  color: #81858e;
  padding: 24px;
}
.shaddows {
  border-radius: 12px;
  box-shadow: 2px 2px 3px 3px #f2f2f2;
}
.column {
  flex-direction: column;
}
.title-green {
  font-size: 22px;
  font-weight: 600;
}
.title-green.heaven {
  color: $base-color-heaven;
}
.container-rel {
  border-radius: 10px;
}
#example {
  position: absolute;
  left: 150px;
  width: 18px;
}
#span-privacidade {
  font-size: 14px;
}
.text-finish {
  font-size: 15px;
  line-height: 1.5;
}

@media (max-width: 1143px) and (min-width: 700px) {
  .title-green {
    font-size: 18px;
  }
  .subtitle {
    font-size: 15px;
  }
}

@media (max-width: 699px) {
  .title-green {
    font-size: 15px;
  }
  .subtitle {
    font-size: 13px;
  }
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
