<template>
  <BaseModal name="modal-example" size="lg" :title="$t('modal_example.text_127')">
    <p class="informativo">
      {{ $t('modal_example.text_128') }}
    </p>
    <p class="informativo">{{ $t('modal_example.text_129') }}</p>

    <div class="video">
      <img v-if="!isHeaven" src="@/assets/img/exemplo-idvenda.png" alt="" />
      <img v-else src="@/assets/img/info_help.png" alt="" />
    </div>

    <template v-slot:footer="{}">
      <div class="botoes">
        <BaseButton variant="info2" class="skiped" @click="close">
          {{ $t('modal_example.text_130') }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: "ModalExample",
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-example")
    }
  }
}
</script>

<style scoped>
.informativo {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
.informativo + .informativo {
  margin-top: 10px;
}
.video {
  margin: 0px auto;
  overflow: hidden;
}

.skiped {
  color: #2133d2;
  background: rgba(33, 51, 210, 0.1);
  border: none;
}
</style>