<template>
  <b-col class="pa-4" style="justify-content: center" cols="12" md="12">
    <!-- Step 01 - Follow up on your complaint -->
    <div class="expande-horizontal centraliza-horizontal">
      <div
        v-if="step === 1"
        class="container-rel"
        style="
          max-width: 701px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          min-height: 30vh;
          width: -webkit-fill-available;
        "
      >
        <b-col class="mb-4" cols="12" md="12" data-anima="top">
          <h4 class="mb-3 green--text title-green" :class="{'heaven': isHeaven}">
            {{ $t('view_claim.text_006') }}
          </h4>
          <span
            class="grey--text"
            style="margin-top: 22px; padding-bottom: 12px"
            >{{ $t('view_claim.text_007') }}</span
          >
        </b-col>
        <!-- Claim Number -->
        <b-col cols="12" md="12" data-anima="top">
          <b-form-group
            :label="$t('view_claim.text_008')"
            label-for="numero_reclamacao"
          >
            <b-form-input
              id="numero_reclamacao"
              name="numero_reclamacao"
              :placeholder="$t('view_claim.text_009')"
              v-model="get_claim_info.id"
              v-validate="'required'"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="submit ? !errors.has('numero_reclamacao') : null"
            >
              {{ $t('view_claim.text_010') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- CPF/CNPJ -->
        <b-col v-if="!isHeaven" class="mt-2" cols="12" md="12" data-anima="top">
          <b-form-group
            :label="$t('view_claim.text_011') + ' ' + $t('app.optional')"
            label-for="cpf_cliente"
          >
          <span
            class="grey--text"
            style="margin-top: 22px; padding-bottom: 12px"
            >{{ $t('app.optional') }}</span
          >
            <b-form-input
              id="cpf_cliente"
              name="cpf_cliente"
              :placeholder="$t('view_claim.text_012')"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              v-model="get_claim_info.document"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="submit ? !errors.has('cpf_cliente') : null"
            >
              {{ $t('view_claim.text_013') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Document Number -->
        <b-col v-if="isHeaven" class="mt-2" cols="12" data-anima="top">
          <b-form-group
            :label="$t('new_claim.text_117') + ' ' + $t('app.optional')"
            label-for="cpf_cliente"
          >
            <b-form-input
              id="cpf_cliente"
              name="cpf_cliente"
              :placeholder="$t('new_claim.text_118')"
              v-model="get_claim.document"
              type="text"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- Email Code -->
        <b-col v-if="confirm" class="mt-2" cols="12" md="12" data-anima="top">
          <b-form-group :label="$t('view_claim.text_014')" label-for="secret">
            <b-form-input
              id="secret"
              name="secret"
              :placeholder="$t('view_claim.text_015')"
              v-model="get_claim_info.secret"
              v-validate="'required|length:6'"
              type="text"
              maxlength="6"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="submit ? !errors.has('secret') : null"
            >
              {{ $t('view_claim.text_016') }}
            </b-form-invalid-feedback>
            <!-- maxlength="6" -->
          </b-form-group>
        </b-col>
        <!-- Buttons -->
        <b-col cols="12" md="12" class="mt-4 px-3">
          <div class="expande-horizontal column centraliza-vertical">
            <BaseButton
              v-if="!loading && confirm"
              style="width: 100%"
              :disabled="!canNext2"
              variant="primary"
              :class="{'heaven': isHeaven}"
              @click="fetch_claim_public"
            >
              {{ $t('view_claim.text_017') }}
            </BaseButton>
            <BaseButton
              v-if="!loading && !confirm"
              style="width: 100%"
              :disabled="!canNext"
              variant="primary"
              :class="{'heaven': isHeaven}"
              @click="sendNewVerificationCode"
            >
              {{ $t('view_claim.text_018') }}
            </BaseButton>
            <BaseButton
              v-if="loading"
              style="width: 100%"
              variant="primary"
              :class="{'heaven': isHeaven}"
              :disabled="loading"
            >
              <b-spinner style="color: #000" small></b-spinner>
            </BaseButton>
          </div>
        </b-col>
      </div>
    </div>
    <!-- Step 02 - Claim Access Code-->
    <div
      v-if="step === 2"
      class="container-rel"
      style="
        max-width: 701px;
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        min-height: 30vh;
        width: -webkit-fill-available;
      "
    >
      <b-col class="mb-4" cols="12" md="12" data-anima="top">
        <h4 class="mb-3 green--text title-green" :class="{'heaven': isHeaven}">
          {{ $t('verification_email.text_119') }}
        </h4>
        <span class="grey--text subtitle" style="margin-top: 22px; padding-bottom: 12px">
          {{ $t('verification_email.text_120') }}
        </span>
      </b-col>
      <!-- Verification Code -->
      <b-col class="mt-2" cols="12" md="12" data-anima="top">
        <b-form-group :label="$t('verification_email.text_121')" label-for="id_compra">
          <b-form-input
            id="id_compra"
            name="id_compra"
            :placeholder="$t('verification_email.text_122')"
            v-model="get_claim.access_code"
            v-validate="'required'"
            type="text"
            :class="{'heaven': isHeaven}"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('id_compra') : null"
          >
            {{ $t('verification_email.text_123') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Reenviar E-mail / Buttons -->
      <b-col cols="12" class="mt-4 px-3">
        <div class="expande-horizontal centraliza-vertical">
          <div style="width: 100%">
            <div class="expande-horizontal centraliza-vertical">
              <span class="grey--text subtitle cursor-pointer" @click="backOne">{{ $t('verification_email.text_124') }}</span>
            </div>
          </div>
          <div class="d-flex">
            <BaseButton
              variant="link"
              :class="{'heaven': isHeaven}"
              class="mr-3"
              style="width: max-content;"
              @click="sendNewVerificationCode"
            >
              {{ $t('verification_email.text_125') }}
            </BaseButton>
            <BaseButton
              v-if="!loadingVerificationEmail"
              :disabled="!get_claim.access_code"
              variant="primary"
              :class="{'heaven': isHeaven}"
              @click="verificarClaimAccessCode"
            >
              {{ $t('new_claim.text_072') }}
            </BaseButton>
            <BaseButton
              v-else
              variant="primary"
              :class="{'heaven': isHeaven}"
              style="min-width: 100px"
              :disabled="loadingVerificationEmail"
            >
              <b-spinner small></b-spinner>
            </BaseButton>
          </div>
        </div>
      </b-col>
    </div>
    <!-- Step 03 - Claim information -->
    <div v-if="step === 3" class="expande-horizontal wrap">
      <b-col cols="12" md="12">
        <b-row>
          <b-col cols="12" md="9">
            <b-row class="aling-items-center">
              <!-- Claim Datails -->
              <b-col data-anima="top" cols="12" md="9">
                <h3 style="font-weight: 600" class="text-uppercase">
                  {{ get_claim.subjective }}
                </h3>
                <div class="expande-horizontal">
                  <div style="display: flex; flex-wrap: no-wrap">
                    <img
                      style="
                        width: 17px;
                        height: 24px;
                        padding-bottom: 3px;
                        margin-right: 6px;
                      "
                      src="../../assets/img/icons/calendario-black.svg"
                    />
                    <span class="grey--text">
                      {{ moment(get_claim.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                    </span>
                    <strong class="ml-3 mr-1">ID: </strong>
                    <span class="grey--text">{{ get_claim.id }}</span>
                    <strong class="ml-3 mr-1" v-if="!get_claim.status">Status:</strong>
                    <span class="grey--text" v-if="!get_claim.status">
                      {{ $t('view_claim.text_019') }}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="3">
                <BaseButton
                  v-if="get_claim.status && get_claim.status === 'close'"
                  variant="invert"
                  class="btn-status"
                >
                  {{ $t('view_claim.text_020') }}
                </BaseButton>
                <BaseButton
                  v-if="get_claim.status && get_claim.status === 'refunded'"
                  variant="invert"
                  class="btn-status"
                >
                  {{ $t('view_claim.text_021') }}
                </BaseButton>
                <BaseButton
                  v-if="get_claim.status && get_claim.status === 'refund_pending'"
                  variant="info3"
                  class="btn-status"
                >
                  {{ $t('view_claim.text_022') }}
                </BaseButton>
                <BaseButton
                  v-if="get_claim.status && get_claim.status === 'refund_denied'"
                  variant="invert"
                  class="btn-status"
                >
                  {{ $t('view_claim.text_023') }}
                </BaseButton>
                <BaseButton
                  v-if="get_claim.status && get_claim.status === 'close_solicitation'"
                  variant="info3"
                  class="btn-status"
                >
                  {{ $t('view_claim.text_024') }}
                </BaseButton>
              </b-col>
              <b-row class="mt-3">
                <b-col cols="12" style="padding-left: 27px">
                  <span
                    style="
                      font-weight: 500;
                      color: #2133d2;
                      background: rgba(33, 51, 210, 0.1);
                      border-radius: 10px;
                      padding: 7px 12px;
                      font-size: 12px;
                    "
                    >{{ getCategory(get_claim.objective) }}</span
                  >
                </b-col>
              </b-row>
              <!-- Contacts -->
              <b-col
                v-if="get_claim.seller_contact"
                cols="12"
                md="12"
                class="mt-3"
              >
                <div
                  style="
                    display: flex;
                    padding: 15px;
                    margin-top: 10px;
                    max-width: 300px;
                    flex-direction: column;
                    border: 1px solid #f2f2f2;
                    border-radius: 6px;
                  "
                >
                  <span class="grey--text">{{ $t('view_claim.text_025') }}</span>
                  <div>
                    <span class="grey--text">
                      {{ $t('view_claim.text_026') }} {{ get_claim.seller_contact.email }}
                    </span>
                  </div>
                  <div>
                    <span class="grey--text">
                      {{ $t('view_claim.text_027') }} {{ get_claim.seller_contact.telephone }}
                    </span>
                  </div>
                </div>
              </b-col>
              <!-- Description -->
              <b-col cols="12" md="12">
                <p class="grey--text pa-5 mt-5">{{ get_claim.description }}</p>
              </b-col>
            </b-row>

            <b-row class="mt-5">
              <!-- Comment Form -->
              <b-col cols="12" md="8" data-anima="top">
                <b-form-textarea
                  id="id_compra"
                  name="id_compra"
                  style="min-height: 100px"
                  maxlength="400"
                  :placeholder="$t('view_claim.text_028')"
                  v-model="answer"
                  v-validate="'required'"
                  type="text"
                  :class="{'heaven': isHeaven}"
                ></b-form-textarea>
                <span style="color: grey; font-size: 11px">
                  {{ answer.length }} / 400</span
                >
                <span
                  v-if="file_ok"
                  style="margin-left: 10px; color: grey; font-size: 11px"
                >
                  {{ $t('view_claim.text_029') }}
                </span>
              </b-col>
              <b-col cols="12" md="1" data-anima="top">
                <label for="file-upload" class="custom-file-upload">
                  <img
                    style="
                      position: relative;
                      margin-left: 14px;
                      top: 14px;
                      height: 18px;
                    "
                    class="pointer"
                    src="../../assets/img/icons/paperclip.svg"
                  />
                </label>
                <input
                  accept="image/*,application/pdf"
                  id="file-upload"
                  type="file"
                  :class="{'heaven': isHeaven}"
                  @change="setFileToClaim"
                />
              </b-col>
              <!-- Comment Button -->
              <b-col cols="12" md="3" data-anima="top">
                <button
                  :disabled="answer.length ? false : true"
                  v-if="!loading"
                  @click="answer_claim"
                  style="
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 45px;
                    display: flex;
                    border: none;
                    font-weight: 500;
                    color: #2133d2;
                    background: rgba(33, 51, 210, 0.1);
                    border-radius: 5px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-right: 11px;
                    padding-left: 11px;
                    cursor: pointer;
                  "
                >
                  <span class="blue--text">{{ $t('view_claim.text_030') }}</span>
                </button>
                <button
                  v-else
                  style="
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 45px;
                    display: flex;
                    border: none;
                    font-weight: 500;
                    color: #2133d2;
                    background: rgba(33, 51, 210, 0.1);
                    border-radius: 5px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-right: 11px;
                    padding-left: 11px;
                  "
                >
                  <b-spinner style="color: #2133d2"></b-spinner>
                </button>
              </b-col>
            </b-row>
            <!-- Claim Historic -->
            <b-row
              v-if="get_answers.length"
              class="expande-horizontal mt-4 mb-4 pr-3"
              style="justify-content: space-between"
            >
              <span class="grey--text mr-3 ml-3 mt-5">
                {{ $t('view_claim.text_031') }}
              </span>
              <span
                @click="toggleShowOnlyFirstAnswer"
                style="
                  position: relative;
                  right: 0px;
                  cursor: pointer;
                  color: #2133d2;
                  font-weight: 600;
                  font-size: 14px;
                "
                class="mt-5"
              >
                {{ $t('view_claim.text_032') }}
              </span>
            </b-row>

            <b-row v-if="!get_answers.length" class="table-body mt-4 justify-content-center">
              <p class="nao-encontrado">{{ $t('view_claim.text_033') }}</p>
            </b-row>
            <!-- Close Claim -->
            <b-col cols="12" md="3" v-if="mobile()">
              <b-row>
                <BaseButton
                  v-if="confirmation === 'encerrar'"
                  style="width: 100%"
                  variant="danger"
                  @click="setConfirmClose(true)"
                >
                  {{ $t('view_claim.text_034') }} ({{ timeoutEncerramento }})
                </BaseButton>
                <BaseButton
                  v-if="
                    personalizedLoading !== 'encerramento-sim' &&
                    confirmation !== 'encerrar' &&
                    !get_claim.status
                  "
                  style="width: 100%"
                  variant="danger"
                  @click="setConfirmClose"
                >
                  {{ $t('view_claim.text_035') }}
                </BaseButton>
                <BaseButton
                  v-if="personalizedLoading === 'encerramento-sim'"
                  style="width: 100%"
                  variant="danger"
                >
                  <b-spinner style="color: #fff"></b-spinner>
                </BaseButton>
              </b-row>
            </b-col>

            <b-row class="pl-1 mt-3 expande-horizontal">
              <b-col v-if="showOnlyFirstAnswer && get_answers.length" cols="12">
                <div class="timeline">
                  <div
                    class="container-timeline right"
                    :class="getClassByResponse(get_answers[0])"
                  >
                    <div class="content">
                      <div
                        class="expande-horizontal pb-4 pr-3"
                        style="width: 100%; justify-content: space-between"
                      >
                        <strong
                          v-if="get_answers[0].type === 'CLIENT'"
                          class="mr-3 ml-3"
                          style="font-size: 14px"
                        >
                          {{ $t('view_claim.text_036') }} -
                          {{ get_answers[0].client ? get_answers[0].client.name : "" }}
                        </strong>
                        <strong
                          v-if="get_answers[0].type === 'SELLER'"
                          class="mr-3 ml-3"
                          style="font-size: 14px"
                        >
                          {{ $t('view_claim.text_037') }} -
                          {{ get_answers[0].user ? get_answers[0].user.name : "" }}
                        </strong>
                        <strong
                          v-if="get_answers[0].type === 'GREENN'"
                          class="mr-3 ml-3"
                          style="font-size: 14px"
                        >
                          {{ $t('view_claim.text_038') }} -
                          {{ get_answers[0].user ? get_answers[0].user.name : "" }}
                        </strong>
                        <span
                          style="
                            position: relative;
                            right: 0px;
                            cursor: pointer;
                          "
                          class="grey--text"
                        >
                          {{ moment(get_answers[0].created_at).format("DD/MM/YYYY [às] HH:mm") }}
                        </span>
                      </div>
                      <div class="expande-horizontal pb-4 ml-3">
                        <p class="grey--text" style="padding: 0px; margin: 0">
                          {{ get_answers[0].answer }}
                        </p>
                      </div>
                      <div class="expande-horizontal pb-4 ml-3">
                        <a
                          v-for="(att, index) in get_answers[0].attachment_answers"
                          :key="att.id"
                          class="link-arquive"
                          :href="att.attachment"
                          target="_blank"
                          @click="openFile(att.attachment)"
                        >
                          <div class="link-arquive-dot-blue"></div>
                          {{ $t('view_claim.text_039') }}-{{ index + 1 }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col v-if="!showOnlyFirstAnswer && get_answers.length" cols="12">
                <div
                  v-for="item in get_answers"
                  :key="item.id"
                  class="timeline wrap"
                >
                  <div class="container-timeline right" :class="getClassByResponse(item)">
                    <div class="content wrap">
                      <div class="expande-horizontal wrap pb-4 pr-3" style="width: 100%; justify-content: space-between">
                        <div class="expande-horizontal pb-0 pr-3" style="justify-content: space-between">
                          <strong
                            style="
                              position: relative;
                              top: -4px;
                              font-size: 14px;
                            "
                            v-if="item.type && item.type === 'CLIENT'"
                            class="mr-3 ml-3"
                          >
                            {{ $t('view_claim.text_036') }} -
                            {{ item.client ? item.client.name : "" }}
                          </strong>
                          <strong
                            style="
                              position: relative;
                              top: -4px;
                              font-size: 14px;
                            "
                            v-if="item.type && item.type === 'SELLER'"
                            class="mr-3 ml-3"
                          >
                            {{ $t('view_claim.text_037') }} -
                            {{ item.user ? item.user.name : "" }}
                          </strong>
                          <strong
                            style="
                              position: relative;
                              top: -4px;
                              font-size: 14px;
                            "
                            v-if="item.type && item.type === 'GREENN'"
                            class="mr-3 ml-3"
                          >
                            {{ $t('view_claim.text_038') }} -
                            {{ item.user ? item.user.name : "" }}</strong
                          >
                          <span
                            style="
                              position: relative;
                              right: 0px;
                              cursor: pointer;
                            "
                            class="grey--text"
                          >
                            {{ moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                          </span>
                        </div>
                        <div class="expande-horizontal pb-4 ml-3 mt-3">
                          <p class="grey--text">{{ item.answer }}</p>
                        </div>
                        <div class="expande-horizontal pb-4 ml-3">
                          <a
                            class="link-arquive"
                            @click="openFile(att.attachment)"
                            :href="att.attachment"
                            target="_blank"
                            v-for="(att, index) in item.attachment_answers"
                            :key="att.id"
                          >
                            <div class="link-arquive-dot-blue"></div>
                            {{ $t('view_claim.text_039') }}-{{ index + 1 }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- Close Claim -->
          <b-col cols="12" md="3" v-if="!mobile()">
            <b-row>
              <BaseButton
                v-if="confirmation === 'encerrar'"
                style="width: 100%"
                variant="danger"
                @click="setConfirmClose(true)"
              >
                {{ $t('view_claim.text_040') }} ({{ timeoutEncerramento }})
              </BaseButton>
              <BaseButton
                v-if="
                  personalizedLoading !== 'encerramento-sim' &&
                  confirmation !== 'encerrar' &&
                  !get_claim.status
                "
                style="width: 100%"
                variant="danger"
                @click="setConfirmClose"
              >
                {{ $t('view_claim.text_041') }}
              </BaseButton>
              <BaseButton
                v-if="personalizedLoading === 'encerramento-sim'"
                style="width: 100%"
                variant="danger"
              >
                <b-spinner style="color: #fff"></b-spinner>
              </BaseButton>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </div>
    <ModalExample />
  </b-col>
</template>

<script>
import moment from "moment";
import ClaimPublic from "../../services/resources/ClaimPublic";
const service = ClaimPublic.build();

import ModalExample from "./ModalExample.vue";
export default {
  data() {
    return {
      confirm: false,
      moment: moment,
      step: this.$route.path === "/acompanhar" ? 1 : 2,
      file_ok: false,
      showOnlyFirstAnswer: false,
      confirmation: "",
      submit: false,
      answer: "",
      answerFile: "",
      get_claim: {},
      get_claim_info: {},
      get_answers: [],
      loading: false,
      personalizedLoading: "",
      timeoutEncerramento: "",
      original_claim: {},
      loadingVerificationEmail: false
    };
  },
  components: { ModalExample },
  watch: {
    step() {
      if (this.step === 1) {
        if (Object.values(this.$route.query).length) {
          this.$router.push({ query: {} });
        }
      }
    },
  },
  computed: {
    canNext() {
      if(this.get_claim_info.id) return true
      return false
    },
    canNext2() {
      if(this.get_claim_info.id && this.get_claim_info.secret) return true
      return false
    },
    isHeaven() {
      return this.$store.getters['getIsHeaven']
    }
  },
  mounted() {
    if (typeof this.$route.query.id != "undefined") {
      this.loading = true;
      this.get_claim_info.id = this.$route.query.id;
      this.get_claim_info.document = this.$route.query.doc;
      this.get_claim_info.secret = this.$route.query.secret;

      let data = {
        id: this.$route.query.id,
        url: `/secret`,
        language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR',
        answer: 'answer_claim'
      };

      if (this.$route.query.secret) {
        data.secret = this.$route.query.secret.substr(0, 6)
        data.url = `/verify/${this.$route.query.id}`
      }

      if(this.$route.query.doc) data.document = this.$route.query.doc

      if(this.$route.query.secret && this.$route.query.rdr) {
        service
          .read({ id: `check-hash?secret=${this.$route.query.secret.substr(0, 6)}&rdr=${this.$route.query.rdr}`})
          .then(res => {
            if(res) {
              this.$router.push({query: {id: this.$route.query.id, secret: this.$route.query.secret } })
              return
            }
          })
          .catch(err => console.error(err))
      }

      service
        .search(data)
        .then((res) => {
          if (this.$route.query.secret) {
            if (res.claim.length) {
              this.get_claim = res.claim[0];
              this.get_answers = [];
              this.get_answers = res.answers.reverse();
              this.step = 3;
              this.loading = false;
            } else {
              this.$bvToast.toast(this.$t('view_claim.text_042'), {
                variant: "info",
                title: this.$t('view_claim.text_043'),
                autoHideDelay: 5000
              });
              this.loaging = false
            }
          } else {
            if (res.success) {
              this.$bvToast.toast(this.$t('view_claim.text_044'), {
                variant: "info",
                title: this.$t('view_claim.text_045'),
                autoHideDelay: 5000
              });
              this.confirm = true;
            } else {
              this.$bvToast.toast(this.$t('view_claim.text_042'), {
                variant: "info",
                title: this.$t('view_claim.text_043'),
                autoHideDelay: 5000
              })
              this.loaging = false
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$bvToast.toast(this.$t('view_claim.text_042'), {
            variant: "warning",
            title: this.$t('view_claim.text_043'),
            autoHideDelay: 5000
          })
        })
        .finally(() => { this.loading = false })
    }
  },
  methods: {
    getCategory(category) {
      if (category) {
        switch (category.toLowerCase()) {
          case "DUPLICATE_PURCHASE": return this.$t('view_claim.text_046')
          case "PRODUCT_NOT_DELIVERED": return this.$t('view_claim.text_047')
          case "EXPECTATIONS_NOT_MEETED": return this.$t('view_claim.text_048')
          case "GAVE_UP_BUYING": return this.$t('view_claim.text_049')
          case "BUY_WRONG": return this.$t('view_claim.text_050')
          default: return category
        }
      } else return category
    },
    mobile() {
      return window.innerWidth <= 768;
    },
    example() {
      this.$bvModal.show("modal-example");
    },
    fetch_claim_admin(payload, answers) {
      this.get_claim = payload;
      this.get_claim_info = payload;
      this.get_answers = answers.reverse();
      this.step = 2;
      this.$bvModal.show("modal-view-claim");
    },
    close_claim: ({ commit, dispatch, getters }) => {
      dispatch("startLoading");
      service
        .search({
          url: `/close/${getters.get_claim.id}`,
          language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR'
        })
        .then((res) => {
          // commit('set_claim', res)
          dispatch("stopLoading");
        })
        .catch((e) => {
          dispatch("stopLoading");
        });
    },
    setConfirmClose(confirmation) {
      if (confirmation) {
        this.close_claim();
        this.confirmation = "";
      } else {
        if (this.timeoutEncerramento === "") {
          this.timeoutEncerramento = 5;
        }
        this.confirmation = "encerrar";
        const timeout = setInterval(() => {
          if (this.timeoutEncerramento === "") {
            this.timeoutEncerramento = 5;
          } else {
            if (this.timeoutEncerramento === 0) {
              this.confirmation = "";
              this.timeoutEncerramento = "";
              clearTimeout(timeout);
            } else {
              this.timeoutEncerramento = this.timeoutEncerramento - 1;
            }
          }
        }, 1000);
        timeout();
      }
    },
    close_claim() {
      this.personalizedLoading = "encerramento-sim";
      service
        .update({
            document: this.get_claim_info.document,
            secret: this.get_claim_info.secret.substr(0, 6),
            language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR'
          }, `close/${this.get_claim.id}`
        )
        .then(() => {
          this.$bvToast.toast(this.$t('view_claim.text_051'), {
            variant: "success",
            title: this.$t('view_claim.text_052'),
            autoHideDelay: 5000
          })
          // this.$bvModal.hide('modal-view-claim');
          this.personalizedLoading = "";
          this.confirmation = "";
          this.step = 1;
          this.get_claim = {};
          this.get_claim_info = {};
        })
        .catch(err => {
          console.error(err)
          this.personalizedLoading = "";
          this.confirmation = "";
        });
    },
    fetch_claim_public_consult() {
      let data = { id: this.get_claim_info.id }
      if(this.get_claim_info.document) {
        data.doc = this.get_claim_info.document,
        data.document = this.get_claim_info.document
      }
      this.$router.push({ query: data })
      this.loading = true
      data.url = `/secret/`
      data.language = this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR'
      service
        .search(data)
        .then((res) => {
          if (res.success) {
            this.$bvToast.toast(this.$t('view_claim.text_053'), {
              variant: "info",
              title: this.$t('view_claim.text_045'),
              autoHideDelay: 5000
            })
            this.confirm = true;
          } else {
            this.$bvToast.toast(this.$t('view_claim.text_042'), {
              variant: "info",
              title: this.$t('view_claim.text_043'),
              autoHideDelay: 5000
            });
          }
        })
        .catch(err => {
          console.error(err)
          this.loading = false;
          this.$bvToast.toast(this.$t('view_claim.text_042'), {
            variant: "warning",
            title: this.$t('view_claim.text_043'),
            autoHideDelay: 5000
          })
        })
        .finally(() => { this.loading = false })
    },
    fetch_claim_public(method = null) {
      let data = {
        id: this.get_claim_info.id,
        secret: this.get_claim_info.secret.substr(0, 6),
      }
      if(this.get_claim_info.document) {
        data.doc = this.get_claim_info.document
        data.document = this.get_claim_info.document
      }
      this.$router.push({ query: data })
      data.url = `/verify/${this.get_claim_info.id}`
      data.language = this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR'
      data.answer = method
      this.loading = true
      service
        .search(data)
        .then((res) => {
          if(this.original_claim.document) {
            if(res.claim.length) {
              this.get_claim = res.claim[0]
              this.get_answers = []
              this.get_answers = res.answers.reverse()
              this.step = 3
            } else {
              this.$bvToast.toast(this.$t('view_claim.text_042'), {
                variant: "info",
                title: this.$t('view_claim.text_043'),
                autoHideDelay: 5000
              })
            }
          } else {
            if(res.success) {
              this.original_claim = Object.assign({}, data)
              this.original_claim.sale_id = res.sale_id
              if(method !== 'answer_claim') {
                this.step = 2
              } else {
                this.get_claim = res.claim[0]
                this.get_answers = []
                this.get_answers = res.answers.reverse()
                this.step = 3
              }
            } else {
              this.$bvToast.toast(this.$t('view_claim.text_042'), {
                variant: "info",
                title: this.$t('view_claim.text_043'),
                autoHideDelay: 5000
              })
            }
          }
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('view_claim.text_042'), {
            variant: "warning",
            title: this.$t('view_claim.text_043'),
            autoHideDelay: 5000
          });
        })
        .finally(() => { this.loading = false })
    },
    answer_claim() {
      if (this.answer !== "") {
        this.loading = true;
        var formData = new FormData();
        formData.append("answer", this.answer);
        formData.append("attachment", this.answerFile);
        formData.append("claim_id", this.get_claim.id);
        formData.append("secret", this.get_claim_info.secret.substr(0, 6));
        formData.append("type", "CLIENT");
        formData.append('language', this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR')
        service
          .create(formData, null, "/answer")
          .then(() => {
            this.answer = "";
            this.answerFile = "";
            this.loading = false;
            this.$bvToast.toast(this.$t('view_claim.text_054'), {
              variant: "success",
              title: this.$t('view_claim.text_055'),
              autoHideDelay: 5000
            });
            this.fetch_claim_public('answer_claim');
          })
          .finally(() => {
            this.answer = "";
            this.answerFile = "";
            this.loading = false;
          });
      } else {
        this.$bvToast.toast(this.$t('view_claim.text_056'), {
          variant: "success",
          title: this.$t('view_claim.text_057'),
          autoHideDelay: 5000
        })
      }
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    getClassByResponse(item) {
      const resp = {
        CLIENT: "color-resp-client",
        SELLER: "color-resp-seller",
        GREENN: "color-resp-greenn",
      };
      if (item) {
        return resp[item.type];
      } else {
        return "";
      }
    },
    toggleShowOnlyFirstAnswer() {
      this.showOnlyFirstAnswer = !this.showOnlyFirstAnswer;
    },
    uploadImageSuccess(formData, index, fileList) {
      this.answerFile = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm(this.$t('view_claim.text_058'));
      if (r == true) {
        done();
        this.answerFile.splice(index, 1);
      }
    },
    setFileToClaim(e) {
      this.file_ok = false;
      this.file_ok = true;
      this.answerFile = e.target.files[0];
      this.$bvToast.toast(this.$t('view_claim.text_059'), {
        variant: "info",
        title: this.$t('view_claim.text_060'),
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    async sendNewVerificationCode() {
      this.get_claim = Object.assign({}, this.original_claim)
      this.fetch_claim_public_consult()
    },
    async verificarClaimAccessCode() {
      this.loadingVerificationEmail = true
      await service.create({
        access_code: this.get_claim.access_code,
        sale_id: this.original_claim.sale_id,
        claim_id: this.get_claim_info.id,
        language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR'
      },
      null, "/verify-access-code")
        .then(res => {
          if(res.claim.length) {
            this.get_claim = res.claim[0];
            this.get_answers = [];
            this.get_answers = res.answers.reverse();
            this.step = 3
          } else {
            this.$bvToast.toast(this.$t('view_claim.text_042'), {
              variant: "info",
              title: this.$t('view_claim.text_043'),
              autoHideDelay: 5000
            });
          }
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('new_claim.text_113'), {
            variant: "danger",
            title: this.$t('verification_email.text_126'),
            autoHideDelay: 5000
          })
        })
        .finally(() => { this.loadingVerificationEmail = false })
    },
    backOne() {
      this.get_claim = Object.assign({}, this.original_claim)
      this.step = 1
    }
  }
}
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  background: #f7f7f7;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.avatar- {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  margin-right: 30px;
  display: flex;
  background: #37cf79;
  align-items: center;
  justify-content: center;
}
.red--text {
  color: #ff0c37;
}
.small--text {
  font-size: 12px;
}
.link-arquive {
  background: #f7f7f7;
  padding: 7px 14px;
  border-radius: 5px;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-arquive-dot-blue {
  width: 10px;
  height: 5px;
  border-radius: 5px;
  background: #2133d2;
  position: relative;
  margin-right: 6px;
}
.link-arquive-dot-red {
  width: 10px;
  height: 5px;
  border-radius: 5px;
  background: #ff0c37;
  position: relative;
  margin-right: 6px;
}
.btn-clean {
  border: none;
  background: rgba(0, 0, 0, 0);
}
.strong--text {
  font-weight: bold;
}
.green--text {
  color: #37cf79;
  font-size: 14px;
}
.blue--text {
  color: #2133d2;
  font-size: 14px;
}
.grey--text {
  color: #81858e;
  font-size: 14px;
}
.spacer {
  width: 100%;
}
.button-text-blue {
  background: transparent;
  border: none;
  font-size: 10pt;
  text-align: left;
  font-weight: 700;
}
.box-info {
  border-radius: 6px;
  background: #f5f5f5;
  font-size: 11pt;
  color: #81858e;
  padding: 24px;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::before {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #ededf0;
  top: 0;
  width: 1px;
  bottom: 0;
}

.container-timeline {
  padding-top: -20px;
  padding-left: 20px;
  position: relative;
  background-color: inherit;
}

.container-timeline::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 15px;
  top: 0px;
  border-radius: 50%;
  z-index: 1;
}
.color-resp-client::after {
  background-color: #ff0c37;
  box-shadow: 0px 0px 5px #ff0c37;
}
.color-resp-seller::after {
  background: #2133d2;
  box-shadow: 0px 0px 10px #2133d2;
}
.color-resp-greenn::after {
  background: #37cf79;
  box-shadow: 0px 0px 10px #37cf79;
}
.left {
  left: 0;
}
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 1;
}

.right::after {
  left: -4px;
}

.content {
  background-color: white;
  position: relative;
  top: -5px;
}

@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  .container-timeline {
    width: 100%;
  }

  .container::before {
    left: 60px;
  }

  .right {
    left: 0%;
  }
}
.title-green {
  font-size: 22px;
  font-weight: 600;
}
.title-green.heaven {
  color: $base-color-heaven;
}
.container-rel {
  border-radius: 10px;
}
#example {
  position: absolute;
  left: 120px;
  width: 18px;
}
.info-status {
  font-size: 14px;
}
.status-item img {
  height: 18px;
  margin-top: -2px;
}
.btn-status {
  font-size: 13px;
  height: 45px !important;
  padding: 0 20px !important;
  min-width: 210px;
}
.btn-status:hover {
  transform: scale(1) !important;
  cursor: initial;
}

@media (max-width: 1143px) and (min-width: 700px) {
  .title-green {
    font-size: 18px;
  }
}

@media (max-width: 699px) {
  .title-green {
    font-size: 15px;
  }
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

</style>


